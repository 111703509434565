import React, { MouseEventHandler, useState } from 'react';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { ClickAwayListener, Drawer } from '@mui/material';

import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { useUserData } from 'context/hooks/useUserData';
import { logout } from 'utils/auth';
import { MobileNavMenu } from './MobileNavMenu';
import { Socials } from './Socials';
import { HeaderTooltip } from './HeaderTooltip';
import st from './MobileHeader.module.scss';

export const MobileHeader = () => {
    const {
        userData: { wallet, name: userName, wallets }
    } = useUserData();

    const [drawerOpened, setDrawerOpened] = useState(false);
    const toggleDrawer = (newValue?: boolean) =>
        setDrawerOpened((prev) => (newValue !== undefined ? newValue : !prev));

    const [userTooltipOpen, setUserTooltipOpen] = useState(false);
    const handleUserTooltipOpen = () => setUserTooltipOpen(true);
    const handleUserTooltipClose = () => setUserTooltipOpen(false);

    const handleLogout: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        logout();
    };

    return (
        <div className={st.mobileHeaderContainer}>
            <div className={st.mobileHeader}>
                <div className={st.menuIcon} onClick={() => toggleDrawer()}>
                    <MenuRoundedIcon />
                </div>
                <LogoIcon className={st.logoIcon} />
                <ClickAwayListener onClickAway={handleUserTooltipClose}>
                    <div>
                        <HeaderTooltip
                            userTooltipOpen={userTooltipOpen}
                            handleOpen={handleUserTooltipOpen}
                            handleClose={handleUserTooltipClose}
                            logout={handleLogout}
                            userName={userName}
                            wallet={wallet}
                            wallets={wallets}
                        />
                    </div>
                </ClickAwayListener>
            </div>
            <Drawer
                open={drawerOpened}
                onClose={() => toggleDrawer(false)}
                classes={{ paper: st.sideMenuPaper }}>
                <MobileNavMenu onClose={() => toggleDrawer(false)} />
                <Socials />
            </Drawer>
        </div>
    );
};
