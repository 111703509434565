import React from 'react';
// import { DISCORD_URL, TELEGRAM_URL, TWITTER_URL } from 'constants/common';
// import { DiscordIcon, TelegramIcon, TwitterIcon } from 'assets/icons/nav';
import { DISCORD_URL, TWITTER_URL } from 'constants/common';
import { DiscordIcon, TwitterIcon } from 'assets/icons/nav';
import st from './Socials.module.scss';

export const Socials = () => {
    return (
        <div className={st.socials}>
            <a href={DISCORD_URL} target="_blank" rel="noreferrer">
                <DiscordIcon />
            </a>
            {/* <a href={TELEGRAM_URL} target="_blank" rel="noreferrer">
                <TelegramIcon />
            </a> */}
            <a href={TWITTER_URL} target="_blank" rel="noreferrer">
                <TwitterIcon />
            </a>
        </div>
    );
};
