import React, { FC } from 'react';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import cn from 'classnames';
import st from './BackButton.module.scss';

interface Props {
    onClick?: () => void;
    isShort?: boolean;
    className?: string;
}

export const BackButton: FC<Props> = ({ onClick, isShort, className }) => (
    <button className={cn(st.backButton, { [st.short]: isShort }, className)} onClick={onClick}>
        <ArrowBackRoundedIcon fontSize="small" />
        {!isShort && 'Back'}
    </button>
);
