import { BLAST_CHAIN_ID, BLAST_NAME } from 'constants/chains';
import { getConnectorClient } from '@wagmi/core';
import { PATHS } from 'constants/paths';
import { useConfirmModal } from 'context/hooks/useConfirmModal';
import { Link } from 'react-router-dom';
import { Connector, useSignMessage } from 'wagmi';
import { wagmiConfig } from 'configs/wagmi';
import { detectMob } from 'utils/userAgent';

interface Params {
    withoutModal?: boolean;
}

export const useCustomSignMessage = (params?: Params) => {
    const { withoutModal } = params || {};
    const { signMessageAsync } = useSignMessage();
    const { openModal, closeModal } = useConfirmModal();

    const isMobile = detectMob();

    const handleSignMessage = async (message: string, connector?: Connector) => {
        try {
            if (!withoutModal || isMobile) {
                return await new Promise<`0x${string}`>((resolve, reject) => {
                    openModal({
                        title: 'Sign message',
                        description: (
                            <div style={{ maxWidth: 260 }}>
                                By connecting your wallet and using Petobots, you agree to our{' '}
                                <Link to={PATHS.TERMS_OF_USE} target="_blank">
                                    Terms of Service
                                </Link>{' '}
                                and{' '}
                                <Link to={PATHS.PRIVACY_POLICY} target="_blank">
                                    Privacy Policy
                                </Link>
                                .
                            </div>
                        ),
                        okButtonProps: {
                            text: 'Accept and Sign',
                            onClick: async () => {
                                try {
                                    const signature = await signMessageAsync({
                                        message,
                                        connector
                                    });
                                    closeModal();
                                    resolve(signature);
                                } catch (err) {
                                    reject(err);
                                }
                            }
                        },
                        cancelButtonProps: {
                            text: 'Cancel',
                            onClick: () => {
                                reject();
                                closeModal();
                            }
                        }
                    });
                });
            } else {
                return await signMessageAsync({ message, connector });
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            console.log('sign message error: ', { err });
            if (err?.name === 'SwitchChainError') {
                openModal({
                    title: 'Switch network',
                    description: (
                        <div style={{ maxWidth: 260 }}>
                            {`Please change your network on ${BLAST_NAME}, chainId: ${BLAST_CHAIN_ID}.`}
                        </div>
                    ),
                    okButtonProps: {
                        text: 'Ok',
                        onClick: async () => {
                            closeModal();
                            const data = await getConnectorClient(wagmiConfig);
                            console.log('data: ', data);
                            try {
                                await handleSignMessage(message, connector);
                            } catch {
                                console.log('SwitchChainError sign message error');
                            }
                        }
                    }
                });
            } else {
                throw err;
            }
        }
    };

    return {
        signMessageAsync: handleSignMessage
    };
};
