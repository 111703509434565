export type WalletsTypes = 'MetaMask' | 'WalletConnect';

export interface WalletDto {
    net: string;
    user_uuid: string;
    wallet_address: `0x${string}`;
    app?: WalletsTypes;
    default: boolean;
    default_start: string;
    payout_hold_time_end: string;
}

export enum WalletNetworkNames {
    Polygon = 'polygon',
    Reef = 'reef',
    BobaBnb = 'boba_bnb',
    Myria = 'myria',
    Blast = 'blast'
}
