import axios, { InternalAxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import { BACKEND_URL } from 'constants/common';
import { logout } from 'utils/auth';
import { getTokenLS } from 'utils/localStorage';

export const onSuccessResponseCallback = (response: AxiosResponse) => response;

const onSuccessRequestCallback = (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };

    let headers = newConfig.headers as RawAxiosRequestHeaders;
    if (!(headers as RawAxiosRequestHeaders)) {
        headers = {};
    }

    const token = getTokenLS();

    if (!newConfig.headers.Authorization && token) {
        // eslint-disable-next-line no-param-reassign
        newConfig.headers.Authorization = `Bearer ${token}`;
    }
    // eslint-disable-next-line no-param-reassign
    newConfig.headers['Content-Type'] = 'application/json';

    return newConfig;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onErrorResponseCallback = (error: any) => {
    if (error.config.url !== '/api/auth/login' && error.response?.status === 401) {
        logout();
    }
    return Promise.reject(error);
};

export const apiClient = axios.create({
    baseURL: BACKEND_URL
});

apiClient.interceptors.request.use(onSuccessRequestCallback);

apiClient.interceptors.response.use(onSuccessResponseCallback, onErrorResponseCallback);
