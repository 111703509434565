import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { NavMenu } from './NavMenu';
import { useUserData } from 'context/hooks/useUserData';
import { logout } from 'utils/auth';
import { Socials } from './Socials';
import { PATHS } from 'constants/paths';
// import { apiGetBalances } from 'api/endpoints/wallet';
// import { useSnackbar } from 'notistack';
// import { IS_STAGING } from 'constants/common';
import { HeaderTooltip } from './HeaderTooltip';
import st from './Header.module.scss';

export const Header = () => {
    const {
        userData: { wallet, name: userName, wallets }
    } = useUserData();
    const { pathname } = useLocation();

    const [userTooltipOpen, setUserTooltipOpen] = useState(false);

    const handleOpen = () => setUserTooltipOpen(true);
    const handleClose = () => setUserTooltipOpen(false);

    // const { enqueueSnackbar } = useSnackbar();
    // const [loadingBalance, setLoadingBalance] = useState(false);

    // const handleCheckBalance = () => {
    //     setLoadingBalance(true);
    //     apiGetBalances()
    //         .then(({ data }) => {
    //             enqueueSnackbar(`Balance: ${data?.cybercreds?.amount || 0} CYBERCREDS`, {
    //                 variant: 'success',
    //                 autoHideDuration: 5000
    //             });
    //         })
    //         .finally(() => setLoadingBalance(false));
    // };

    if (pathname === PATHS.LOGIN) {
        return null;
    }

    return (
        <header className={st.header}>
            <div className={st.petobotsLogo}>
                <LogoIcon />
                Petobots
            </div>
            <NavMenu />
            <Socials />
            <HeaderTooltip
                userTooltipOpen={userTooltipOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                logout={logout}
                userName={userName}
                wallet={wallet}
                wallets={wallets}
            />
        </header>
    );
};
