import { Chain } from 'viem';

// export const hasMetaMask = typeof window.ethereum !== 'undefined';
// export const PROJECT_ID = process.env.PROJECT_ID;

export const BLAST_NAME = process.env.REACT_APP_BLAST_MAINNET_NAME;
export const BLAST_CHAIN_ID = +process.env.REACT_APP_BLAST_MAINNET_CHAIN_ID;
export const BLAST_RPC_URL = process.env.REACT_APP_BLAST_MAINNET_RPC_URL;
export const BLAST_BLOCK_EXPLORER = process.env.REACT_APP_BLAST_MAINNET_BLOCK_EXPLORER;

const TESTNETS_IDS = [80001, 9728, 5, 168587773];

export const BLAST_CHAIN = {
    chainId: BLAST_CHAIN_ID,
    id: BLAST_CHAIN_ID,
    name: BLAST_NAME,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
        default: {
            http: [BLAST_RPC_URL]
        },
        public: {
            http: [BLAST_RPC_URL]
        }
    },
    blockExplorers: {
        etherscan: {
            name: 'Blastscan',
            url: BLAST_BLOCK_EXPLORER
        },
        default: {
            name: 'Blastscan',
            url: BLAST_BLOCK_EXPLORER
        }
    },
    testnet: TESTNETS_IDS.includes(BLAST_CHAIN_ID)
} as const satisfies Chain & { chainId: number };

export const CHAINS = {
    [BLAST_CHAIN_ID]: BLAST_CHAIN
};
