import { FC } from 'react';
import { Modal } from '@mui/material';
import { ReactComponent as MetaMaskIcon } from 'assets/icons/wallets/metamask.svg';
import { ReactComponent as WalletConnectIcon } from 'assets/icons/wallets/wallet-connect.svg';
import st from './WalletSelectModal.module.scss';

interface Props {
    onConnectWalletConnect: () => void | Promise<void>;
    onConnectMetamask: () => void | Promise<void>;
    open: boolean;
    close: () => void;
}

export const WalletSelectModal: FC<Props> = ({
    onConnectMetamask,
    onConnectWalletConnect,
    open,
    close
}) => {
    const handleConnectMetamask = () => {
        onConnectMetamask();
        close();
    };
    const handleConnectWalletConnect = () => {
        onConnectWalletConnect();
        close();
    };

    return (
        <Modal open={open} onClose={close}>
            <div className={st.walletSelectModal}>
                <div className="body-lg semi-bold">Connect wallet</div>
                <div className={st.walletsButtons}>
                    <button className={st.walletButton} onClick={handleConnectMetamask}>
                        <MetaMaskIcon />
                        <span>Metamask</span>
                    </button>
                    <button className={st.walletButton} onClick={handleConnectWalletConnect}>
                        <WalletConnectIcon />
                        <span>WalletConnect</span>
                    </button>
                </div>
            </div>
        </Modal>
    );
};
