import { ConfirmModalParams } from 'types/modal';
import { useStore } from './useStore';
import { closeModal, openModal } from 'context';

export const useConfirmModal = () => {
    const { dispatch, state } = useStore();

    const handleOpenModal = (params: ConfirmModalParams) => {
        dispatch(openModal(params));
    };
    const handleCloseModal = () => {
        dispatch(closeModal);
    };

    return {
        modal: state.modal,
        openModal: handleOpenModal,
        closeModal: handleCloseModal
    };
};
