import { FC, useRef } from 'react';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as DisconnectIcon } from 'assets/icons/disconnect.svg';
import { WalletDto } from 'types/wallet';
import { getShortAddress } from 'utils/parser';
import { useScroll } from 'hooks/useScroll';
import { useConfirmModal } from 'context/hooks/useConfirmModal';
import { useDeleteWallet } from 'api/hooks/useDeleteWallet';
import st from './HeaderWalletsList.module.scss';

interface Props {
    defaultWallet?: string;
    wallets?: WalletDto[];
    openWalletSelectModal: () => void;
    hasMetaMask: boolean;
    connect: () => void;
    closeTooltip: () => void;
}

export const HeaderWalletsList: FC<Props> = ({
    defaultWallet,
    wallets = [],
    openWalletSelectModal,
    hasMetaMask,
    connect,
    closeTooltip
}) => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const { isBottom, isTop, onScroll } = useScroll(scrollRef);

    const { enqueueSnackbar } = useSnackbar();

    const { openModal, closeModal } = useConfirmModal();
    const { deleteWallet } = useDeleteWallet({
        onSuccess: () => {
            closeModal();
            enqueueSnackbar('Wallet removed successfully', { variant: 'success' });
        }
    });

    const handleOpenDisconnectModal = (wallet: `0x${string}`) => {
        closeTooltip();
        openModal({
            title: 'Are you sure you want to remove the wallet from the list?',
            description:
                'You will no longer receive Daily Points for NFT cards located in this wallet. But you can link it to your account again if necessary.',
            okButtonProps: {
                text: 'Remove',
                onClick: () => deleteWallet(wallet),
                color: 'gray'
            },
            cancelButtonProps: {
                text: 'Cancel',
                onClick: closeModal
            }
        });
    };

    const handleConnect = () => {
        closeTooltip();
        connect();
    };

    return (
        <div
            ref={scrollRef}
            className={cn(st.userWalletsContainer, 'small-scroll', {
                [st.topShadow]: !isTop,
                [st.bottomShadow]: !isBottom
            })}
            onScroll={onScroll}>
            <div className={st.userWallets}>
                <div
                    className={st.walletItem}
                    onClick={hasMetaMask ? openWalletSelectModal : handleConnect}>
                    <div className={cn(st.walletAddress, st.addWallet, 'body-sm')}>
                        <AddIcon />
                        Add wallet
                    </div>
                </div>
                {wallets?.map(({ wallet_address }) => (
                    <div className={st.walletItem} key={wallet_address}>
                        <div className={cn(st.walletAddress, 'body-sm')}>
                            {getShortAddress(wallet_address)}
                            {wallet_address === defaultWallet && (
                                <div className="body-xs tertiary-text">default wallet</div>
                            )}
                        </div>
                        {wallet_address !== defaultWallet && (
                            <div
                                className={st.disconnect}
                                onClick={() => handleOpenDisconnectModal(wallet_address)}>
                                <DisconnectIcon />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
