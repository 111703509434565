import { useMemo, useState } from 'react';
import { Connector, useAccountEffect, useConnect, useDisconnect } from 'wagmi';
import { getConnectorClient, disconnect } from '@wagmi/core';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { injected } from 'wagmi/connectors';
import { BLAST_CHAIN } from 'constants/chains';
import { useCustomSwitchChain } from './useCustomSwitchChain';
import { wagmiConfig } from 'configs/wagmi';
import { WalletsTypes } from 'types/wallet';

export interface OnConnectedParams {
    address: `0x${string}`;
    chainId?: number;
    connectorName: WalletsTypes;
    connector?: Connector;
}

interface Params {
    onConnected?: (data: OnConnectedParams) => Promise<void>;
}

export const useConnectWallet = (params?: Params) => {
    const { onConnected } = params || {};
    const { connectAsync, connectors } = useConnect();
    const { disconnectAsync } = useDisconnect();
    const [loading, setLoading] = useState(false);
    const [isCurrentConnect, setIsCurrentConnect] = useState(false);
    // const { connector } = useAccount();

    const { open, close } = useWeb3Modal();
    const { switchChainAsync } = useCustomSwitchChain();

    const hasMetaMask = useMemo(
        () =>
            connectors.some(
                (connector) => connector.type === 'injected' && connector.name === 'MetaMask'
            ),
        [connectors.length]
    );

    const handleOnConnected = async (data: {
        isReconnected?: boolean;
        address: `0x${string}`;
        chainId?: number;
        connector: Connector;
    }) => {
        const { isReconnected, address, chainId, connector } = data;
        if (isReconnected || connector?.name === 'MetaMask') {
            return;
        }

        setLoading(true);
        try {
            if (chainId !== BLAST_CHAIN.id) {
                await switchChainAsync(BLAST_CHAIN.id, connector);
            }
            onConnected &&
                (await onConnected({
                    address,
                    chainId: BLAST_CHAIN.id,
                    connectorName: 'WalletConnect',
                    connector
                }));
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            console.log('connect error: ', err);
            disconnectAsync();
            close();
        } finally {
            setLoading(false);
            setIsCurrentConnect(false);
        }
    };

    const handleConnect = async () => {
        try {
            await disconnect(wagmiConfig);
            setIsCurrentConnect(true);
            const connectorCilent = await getConnectorClient(wagmiConfig);
            if (connectorCilent) {
                await disconnect(wagmiConfig);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            console.log('disconnect error: ', err);
        }
        open({ view: 'Connect' });
    };

    const handleConnectMetamask = async () => {
        setLoading(true);
        try {
            await disconnectAsync();
            setIsCurrentConnect(true);
            const { accounts } = await connectAsync({
                chainId: BLAST_CHAIN.id,
                connector: injected({ target: 'metaMask' })
            });
            const address = accounts[0];
            onConnected &&
                (await onConnected({
                    address,
                    chainId: BLAST_CHAIN.id,
                    connectorName: 'MetaMask'
                }));
            // const { data } = await apiLoginMessage(accounts[0]);
            // const signature = await signMessageAsync(data.message);

            // const {
            //     data: { token, auth_token }
            // } = await apiVerifySignature(accounts[0], signature, referral || undefined);
            // if (auth_token) {
            //     setUserData({ wallet: accounts[0], token: auth_token });
            //     setTokenLS(auth_token);
            //     navigate(PATHS.DASHBOARD);
            // } else {
            //     setUserData({ wallet: accounts[0], tempToken: token });
            //     setMessageSignedAddress(accounts[0]);
            // }
        } catch (err) {
            console.log('connect metamask error: ', err);
        } finally {
            setLoading(false);
        }
    };

    useAccountEffect({
        onConnect: isCurrentConnect ? handleOnConnected : undefined
    });

    return {
        connect: handleConnect,
        connectMetamask: handleConnectMetamask,
        hasMetaMask,
        loading
    };
};
