import { useEffect } from 'react';

export const useSetSize = () => {
    useEffect(() => {
        const handleOnResize = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        window.addEventListener('resize', handleOnResize);

        return () => {
            window.removeEventListener('resize', handleOnResize);
        };
    }, []);
};
