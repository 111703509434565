import React, { FC } from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { MENU_ITEMS } from './menuItems';
import st from './MobileNavMenu.module.scss';

interface Props {
    onClose: () => void;
}

export const MobileNavMenu: FC<Props> = ({ onClose }) => {
    const { pathname } = useLocation();

    return (
        <nav className={st.mobileNavMenu}>
            {MENU_ITEMS.map((item) => (
                <React.Fragment key={item.path}>
                    {item.comingSoon ? (
                        <div
                            className={cn(
                                st.navMenuItem,
                                item.path === pathname && st.active,
                                item.comingSoon && st.disabled
                            )}>
                            {item.icon}
                            <div className={st.title}>{item.title}</div>
                            <div className={st.comingSoon}>Coming soon</div>
                        </div>
                    ) : (
                        <Link
                            to={item.path}
                            onClick={onClose}
                            className={cn(
                                st.navMenuItem,
                                item.path === pathname && st.active,
                                item.comingSoon && st.disabled
                            )}>
                            {item.icon}
                            <div className={st.title}>{item.title}</div>
                        </Link>
                    )}
                </React.Fragment>
            ))}
        </nav>
    );
};
