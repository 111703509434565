import { FC, MouseEventHandler, useState } from 'react';
import { ClickAwayListener, Tooltip } from '@mui/material';
import cn from 'classnames';
import { Button } from 'components/shared/buttons';
import { getShortAddress } from 'utils/parser';
import { WalletSelectModal } from 'components/LoginPage/WalletSelectModal';
import { useAddWallet } from 'contract/hooks/useAddWallet';
import { WalletDto } from 'types/wallet';
import { HeaderWalletsList } from './HeaderWalletsList';
import st from './HeaderTooltip.module.scss';

interface Props {
    userTooltipOpen: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    logout: MouseEventHandler<HTMLButtonElement>;
    userName?: string;
    wallet?: string;
    wallets?: WalletDto[];
}

export const HeaderTooltip: FC<Props> = ({
    userTooltipOpen,
    handleOpen,
    handleClose,
    logout,
    userName,
    wallet,
    wallets
}) => {
    const [walletSelectModalOpen, setWalletSelectModalOpen] = useState(false);
    const handleOpenWalletSelectModal = () => {
        handleClose();
        setWalletSelectModalOpen(true);
    };
    const handleCloseWalletSelectModal = () => setWalletSelectModalOpen(false);

    const { connect, connectMetamask, hasMetaMask } = useAddWallet();

    const handleLogout = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleClose();
        logout(event);
    };

    const handleConnect = () => {
        handleClose();
        connect();
    };

    const defaultWallet = wallets?.find((w) => w.default);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                <Tooltip
                    open={userTooltipOpen}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    classes={{
                        tooltip: st.userTooltip
                    }}
                    title={
                        <div className={st.userTooltipContent}>
                            <div className={st.logoutButtonContainer}>
                                <Button
                                    className={st.logoutButton}
                                    onClick={handleLogout}
                                    variant="gray">
                                    Sign out
                                </Button>
                            </div>
                            <div className={cn(st.walletsTitle, 'body-md semi-bold')}>
                                My wallets
                            </div>
                            {userTooltipOpen && (
                                <HeaderWalletsList
                                    defaultWallet={defaultWallet?.wallet_address || wallet}
                                    wallets={wallets}
                                    closeTooltip={handleClose}
                                    hasMetaMask={hasMetaMask}
                                    connect={handleConnect}
                                    openWalletSelectModal={handleOpenWalletSelectModal}
                                />
                            )}
                        </div>
                    }
                    placement="bottom"
                    PopperProps={{
                        disablePortal: true,
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10]
                                }
                            }
                        ]
                    }}
                    enterTouchDelay={0}
                    disableTouchListener
                    disableFocusListener
                    disableHoverListener>
                    <div
                        onClick={userTooltipOpen ? handleClose : handleOpen}
                        className={cn(st.userInfo, 'body-sm secondary-text')}>
                        {userName || (wallet && getShortAddress(wallet))}
                        <div className={st.userAvatar} />
                    </div>
                </Tooltip>

                <WalletSelectModal
                    open={walletSelectModalOpen}
                    close={handleCloseWalletSelectModal}
                    onConnectMetamask={connectMetamask}
                    onConnectWalletConnect={connect}
                />
            </div>
        </ClickAwayListener>
    );
};
