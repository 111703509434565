import { useState } from 'react';
import { Connector } from 'wagmi';
import { useSnackbar } from 'notistack';
import { WalletNetworkNames, WalletsTypes } from 'types/wallet';
import { useConnectWallet } from './useConnectWallet';
import { useCustomSignMessage } from './useCustomSignMessage';
import { apiLoginMessage } from 'api/endpoints/auth';
import { apiPostWallet } from 'api/endpoints/wallet';
import { useUserData } from 'context/hooks/useUserData';

interface Params {
    onSuccess?: () => void;
}

export const useAddWallet = (params?: Params) => {
    const { onSuccess } = params || {};
    const { enqueueSnackbar } = useSnackbar();
    const { signMessageAsync } = useCustomSignMessage({ withoutModal: true });
    const [loading, setLoading] = useState(false);
    const { setUserData } = useUserData();

    const handleOnConnected = async (data: {
        address: `0x${string}`;
        chainId?: number;
        connectorName: WalletsTypes;
        connector?: Connector;
    }) => {
        const { address, connectorName, connector } = data;

        setLoading(true);
        try {
            const { data } = await apiLoginMessage(address);
            const signature = await signMessageAsync(data.message, connector);
            if (!signature) {
                throw new Error('Signature is empty');
            }
            const { data: wallets } = await apiPostWallet({
                wallet: address,
                net: WalletNetworkNames.Blast,
                signature,
                app: connectorName
            });

            setUserData({
                wallets: wallets.map((walletInfo) => ({
                    ...walletInfo,
                    app: walletInfo.app || 'MetaMask'
                }))
            });

            enqueueSnackbar('Wallet added successfully', {
                variant: 'success'
            });
            if (onSuccess) {
                onSuccess();
            }
            setLoading(false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            const additionMeassage = err?.response?.data?.message || '';
            enqueueSnackbar(`Adding wallet error. ${additionMeassage}`, {
                variant: 'error'
            });
            setLoading(false);
            throw err;
        }
    };

    const {
        connect,
        connectMetamask,
        hasMetaMask,
        loading: connectWalletLoading
    } = useConnectWallet({
        onConnected: handleOnConnected
    });

    return {
        connect,
        connectMetamask,
        hasMetaMask,
        loading: connectWalletLoading || loading
    };
};
