export const PATHS = {
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    LEADERBOARD: '/leaderboard',
    STAKE: '/stake',
    STAKE_ETH: '/stake/eth',
    STAKE_BLAST: '/stake/blast',
    PLAY: '/play',
    TERMS_OF_USE: '/terms-of-use',
    PRIVACY_POLICY: '/privacy-policy'
};

export const PATHS_WITHOUT_HEADER = [PATHS.LOGIN, PATHS.TERMS_OF_USE, PATHS.PRIVACY_POLICY];
