import React, { FC, useState } from 'react';
import { Fade, Modal } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import cn from 'classnames';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ConfirmModalParams } from 'types/modal';
import { Button } from '../buttons';
import { useConfirmModal } from 'context/hooks/useConfirmModal';
import st from './ConfirmModal.module.scss';

export const ConfirmModal: FC<ConfirmModalParams> = ({
    open,
    onClose,
    className,
    okButtonProps,
    cancelButtonProps,
    title,
    description,
    disableClose,
    withoutLogo,
    content,
    hasCloseIcon
}) => {
    const [loading, setLoading] = useState(false);
    const { closeModal } = useConfirmModal();
    const handleOnOk =
        okButtonProps?.onClick &&
        (async () => {
            setLoading(true);
            await okButtonProps.onClick();
            setLoading(false);
        });

    const handleClose = () => {
        if (!disableClose) {
            closeModal();
        }
        onClose && onClose();
    };

    if (!open) {
        return null;
    }

    return (
        <Modal
            className={cn(st.confirmModal, className)}
            open={open}
            onClose={disableClose ? undefined : handleClose}>
            <Fade in={open}>
                <div className={st.container}>
                    <div className={cn(st.confirmModalContent, 'modalContent')}>
                        {!withoutLogo && (
                            <div className={st.confirmModalHeader}>
                                <LogoIcon />
                            </div>
                        )}
                        {hasCloseIcon && !disableClose && (
                            <div className={st.closeIcon} onClick={closeModal}>
                                <CloseRoundedIcon />
                            </div>
                        )}
                        <div className={cn(st.title, 'title')}>{title}</div>
                        {description && <div className={st.description}>{description}</div>}
                        {content || null}
                        <div className={st.buttons}>
                            <Button
                                className={st.button}
                                variant={okButtonProps?.color || 'green'}
                                onClick={handleOnOk}
                                loading={loading}>
                                {okButtonProps?.text || 'Ok'}
                            </Button>
                            {cancelButtonProps && (
                                <Button
                                    className={st.button}
                                    variant="gray"
                                    onClick={cancelButtonProps?.onClick}
                                    disabled={loading}>
                                    {cancelButtonProps?.text || 'Cancel'}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
