import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { PATHS } from 'constants/paths';
import { useUserData } from 'context/hooks/useUserData';
// import { useAccount } from 'wagmi';

interface PrivatePageProps {
    children?: React.ReactNode;
}

export const PrivatePage: React.FC<PrivatePageProps> = ({ children }) => {
    const { userData } = useUserData();
    const { token, wallet } = userData || {};
    // const { address } = useAccount();

    const isAuth = useMemo(() => !!token && !!wallet, [token]);

    if (!isAuth) return <Navigate to={PATHS.LOGIN} />;

    return <>{children}</>;
};
