import React, { Dispatch } from 'react';
import { Actions } from './actions';
import {
    ActionsType,
    StateType,
    ResetStore,
    UserState,
    SetUserData,
    SetModalOpen,
    SetModalClose,
    SetDailyReward,
    ResetDailyReward
} from './types';
import { getContextLS } from 'utils/localStorage';
import { ConfirmModalParams } from 'types/modal';

export const initialState: StateType = {
    user: {}
};

export const setUserDataAction = (userData: UserState): SetUserData => ({
    type: Actions.SET_USER_DATA,
    payload: userData
});

export const resetStoreAction: ResetStore = {
    type: Actions.RESET_STORE
};

export const openModal = (modalParams?: ConfirmModalParams): SetModalOpen => ({
    type: Actions.OPEN_MODAL,
    payload: modalParams
});

export const closeModal: SetModalClose = {
    type: Actions.CLOSE_MODAL
};

export const setDailyRewardAction = (dayNumber: number, isSent: boolean): SetDailyReward => ({
    type: Actions.SET_DAILY_REWARD,
    payload: {
        dayNumber,
        isSent
    }
});

export const resetDailyRewardAction: ResetDailyReward = {
    type: Actions.RESET_DAILY_REWARD
};

export const reducer = (state: StateType, action: ActionsType): StateType => {
    switch (action.type) {
        case Actions.SET_USER_DATA:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        case Actions.OPEN_MODAL:
            return {
                ...state,
                modal: {
                    ...action.payload,
                    open: true
                }
            };
        case Actions.CLOSE_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    open: false
                }
            };
        case Actions.SET_DAILY_REWARD:
            return {
                ...state,
                dailyReward: action.payload
            };
        case Actions.RESET_DAILY_REWARD:
            return {
                ...state,
                dailyReward: {}
            };
        case Actions.RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

type ContextProps = {
    state: StateType;
    dispatch: Dispatch<ActionsType>;
};

export const AppContext = React.createContext({} as ContextProps);

export const initializer = (initialValue = initialState) => {
    const stateLS = getContextLS();

    const { user, ...otherStateLS } = stateLS || {};
    const isUserAuth = user && user.wallet && user.token;

    return stateLS
        ? {
              ...initialValue,
              ...otherStateLS,
              ...(isUserAuth ? { user } : {}),
              modal: {}
          }
        : initialValue;
};
