import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { WagmiProvider } from 'wagmi';
import { wagmiConfig } from 'configs/wagmi';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'configs/query';
import { AppProvider } from 'context/AppProvider';
import { SnackbarProvider } from 'notistack';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <WagmiProvider config={wagmiConfig}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <AppProvider>
                        <SnackbarProvider
                            maxSnack={2}
                            autoHideDuration={2000}
                            preventDuplicate
                            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <App />
                        </SnackbarProvider>
                    </AppProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </WagmiProvider>
    </React.StrictMode>
);
