export const BACKEND_URL = process.env.REACT_APP_PETOBOTS_BACKEND_URL;
export const IS_STAGING = process.env.REACT_APP_STAGING === 'true';
export const COMING_SOON_DATE = process.env.REACT_APP_COMING_SOON_DATE;
export const TWITTER_URL = process.env.REACT_APP_TWITTER_LINK;
export const DISCORD_URL = process.env.REACT_APP_DISCORD_LINK;
export const TELEGRAM_URL = process.env.REACT_APP_TELEGRAM_LINK;
export const WEB_CLOSED = process.env.REACT_APP_CLOSED === 'true';
export const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
export const EVENT_BUY_CYBERCRED_AMOUNT = process.env.REACT_APP_EVENT_BUY_CYBERCRED_AMOUNT
    ? +process.env.REACT_APP_EVENT_BUY_CYBERCRED_AMOUNT
    : 100;
