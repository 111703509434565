import { ResponseDto } from 'types/common';
import { BetaPassBoostDto, ReferralDto, UserDto, UserPointsDto } from 'types/user';
import { apiClient } from '../apiClient';
import { withDefaultHandlers } from '../helpers/withDefaultHandlers';

const BASE_PATH = '/api/account/user';
const BASE_BLAST_PATH = '/api/blast/landing/user';

export const apiGetUser = () => withDefaultHandlers(apiClient.get<ResponseDto<UserDto>>(BASE_PATH));

export const apiGetUserReferrals = () =>
    withDefaultHandlers(apiClient.get<ResponseDto<ReferralDto>>(`${BASE_BLAST_PATH}/referrals`));

export const apiGetUserPoints = () =>
    withDefaultHandlers(apiClient.get<ResponseDto<UserPointsDto>>(`${BASE_BLAST_PATH}/points`));

export const apiGetBoosters = () =>
    withDefaultHandlers(
        apiClient.get<ResponseDto<BetaPassBoostDto>>(`/api/blast/landing/user/betapass/buster`)
    );
