import { apiClient } from 'api/apiClient';
import { withDefaultHandlers } from 'api/helpers/withDefaultHandlers';
import { LoginVerifyPostBody } from 'types/auth';
import { ResponseDto } from 'types/common';
import { SocialLinkDto } from 'types/socials';
import { WalletsTypes } from 'types/wallet';

const BASE_PATH = '/api/auth';

export const apiLoginMessage = (wallet: string) =>
    withDefaultHandlers(
        apiClient.get<ResponseDto<{ message: string }>>(`${BASE_PATH}/wallet/signature/message`, {
            params: { wallet }
        })
    );

export const apiVerifySignature = (
    wallet: string,
    signature?: string,
    app?: WalletsTypes,
    referral?: string
) =>
    withDefaultHandlers(
        apiClient.post<ResponseDto<{ token?: string; auth_token?: string }>>(
            '/api/blast/landing/wallet/save',
            {
                wallet,
                signature,
                referrer: referral,
                app
            }
        )
    );

export const apiAuthDiscordLink = (token?: string) =>
    withDefaultHandlers(
        apiClient.get<ResponseDto<SocialLinkDto>>(`api/blast/landing/discord/link`, {
            params: { token }
        })
    );

export const apiAuthTwitterLink = (token?: string) =>
    withDefaultHandlers(
        apiClient.get<ResponseDto<SocialLinkDto>>(`api/blast/landing/twitter/link`, {
            params: { token }
        })
    );

export const apiClaimTokens = (token: string) =>
    withDefaultHandlers(
        apiClient.get<ResponseDto<{ token: string; uuid: string; points: number }>>(
            'api/blast/landing/register',
            { params: { token } }
        )
    );

export const apiGetRegisterPoints = (token: string) =>
    withDefaultHandlers(
        apiClient.get<ResponseDto<{ points: number }>>(`api/blast/landing/registration/points`, {
            params: { token }
        })
    );

export const apiPostLoginVerify = (body: LoginVerifyPostBody) =>
    withDefaultHandlers(
        apiClient.post<ResponseDto<{ token: string }>>(`${BASE_PATH}/wallet/oauth`, body)
    );
// export const apiClaimTokens = (tempToken: string) =>
//     new Promise<ResponseDto<{ token: string; uuid: string; points: number }>>((res) => {
//         setTimeout(() => res({ data: { token: 'test', uuid: '123', points: 447 } }), 1000);
//     });
