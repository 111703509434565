import React from 'react';
import st from './Loader.module.scss';

export const Loader = () => (
    <div className={st.loader}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
);
