import { BLAST_CHAIN_ID, CHAINS } from 'constants/chains';
import { useConfirmModal } from 'context/hooks/useConfirmModal';
import { useSwitchChain, useAccount, Connector } from 'wagmi';

export const useCustomSwitchChain = () => {
    const { switchChainAsync } = useSwitchChain();
    const { chainId: accountChainId } = useAccount();
    const { openModal, closeModal } = useConfirmModal();

    const handleSwitchChain = async (chainId: number, connector?: Connector) => {
        try {
            if (accountChainId !== chainId) {
                await new Promise((resolve, reject) => {
                    openModal({
                        title: 'Switch network',
                        description: (
                            <div style={{ maxWidth: 260 }}>
                                {`This will switch previously selected network to ${CHAINS[chainId].name}`}
                            </div>
                        ),
                        okButtonProps: {
                            text: 'Switch',
                            onClick: async () => {
                                try {
                                    await switchChainAsync({
                                        chainId: BLAST_CHAIN_ID,
                                        connector
                                    });
                                    closeModal();
                                    resolve({});
                                } catch {
                                    reject();
                                }
                            }
                        },
                        cancelButtonProps: {
                            text: 'Cancel',
                            onClick: () => {
                                reject();
                                closeModal();
                            }
                        }
                    });
                });
            }
        } catch (err) {
            console.log('switch chain error: ', err);
            throw err;
        }
    };

    return { switchChainAsync: handleSwitchChain };
};
