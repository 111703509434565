import { TOKEN_LS, CONTEXT_LS } from 'constants/localStorage';
import { StateType } from 'context';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const safeParseJson = (value: any) => {
    try {
        const result = value ? JSON.parse(value) : null;
        return result;
    } catch {
        return null;
    }
};

export const setTokenLS = (token: string) => localStorage.setItem(TOKEN_LS, token);
export const removeTokenLS = () => localStorage.removeItem(TOKEN_LS);
export const getTokenLS = () => localStorage.getItem(TOKEN_LS);

export const setContextLS = (context: StateType) =>
    localStorage.setItem(CONTEXT_LS, JSON.stringify(context));
export const removeContextLS = () => localStorage.removeItem(CONTEXT_LS);
export const getContextLS = (): null | StateType => safeParseJson(localStorage.getItem(CONTEXT_LS));

export const clearLocalStorage = () => {
    removeTokenLS();
    removeContextLS();
};
