import { useCallback } from 'react';
import { logout } from 'utils/auth';
import { useAccount } from 'wagmi';

export const useLogout = () => {
    const { connector } = useAccount();

    const handleLogout = useCallback(async () => {
        if (connector) {
            try {
                typeof connector.disconnect === 'function' && (await connector.disconnect());
            } catch (err) {
                console.log('disconnect error: ', err);
            }
        }
        logout();
    }, [connector]);

    return {
        logout: handleLogout
    };
};
