import React, { FC, ReactNode, useEffect, useReducer } from 'react';
import { AppContext, initializer, initialState, reducer } from './reducer';
import { setContextLS } from 'utils/localStorage';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'constants/paths';

interface Props {
    children: ReactNode;
}

export const AppProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState, initializer);
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname !== PATHS.LOGIN) {
            setContextLS(state);
        }
    }, [state]);

    return (
        <AppContext.Provider
            value={{
                state,
                dispatch
            }}>
            {children}
        </AppContext.Provider>
    );
};
