import { UserState } from 'context/types';
import { useStore } from './useStore';
import { Actions } from 'context/actions';

export const useUserData = () => {
    const { state, dispatch } = useStore();
    const { user } = state;

    const setUserData = (data: UserState) => {
        dispatch({ type: Actions.SET_USER_DATA, payload: data });
    };

    return { userData: user, setUserData };
};
