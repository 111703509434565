import { useState } from 'react';
import { useUserData } from 'context/hooks/useUserData';
import { apiDeleteWallet } from 'api/endpoints/wallet';
import { useSnackbar } from 'notistack';

interface Params {
    onSuccess?: () => void;
}

export const useDeleteWallet = (params?: Params) => {
    const { onSuccess } = params || {};
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {
        setUserData,
        userData: { wallets, wallet }
    } = useUserData();

    const handleDeleteWallet = async (walletAddress: `0x${string}`) => {
        setIsLoading(true);

        try {
            await apiDeleteWallet(walletAddress)
                .then(({ data }) => {
                    setUserData({
                        // TODO: remove mapping
                        wallets:
                            data.map((walletInfo) => ({
                                ...walletInfo,
                                app: walletInfo.app || 'MetaMask'
                            })) || undefined
                    });
                    if (onSuccess) {
                        onSuccess();
                    }
                })
                .catch((err) => {
                    const additionMeassage = err?.response?.data?.message || '';
                    enqueueSnackbar(`Deleting wallet error. ${additionMeassage}`, {
                        variant: 'error'
                    });

                    setIsLoading(false);
                    throw err;
                });
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    return {
        wallets: wallets,
        defaultWallet: wallet,
        isLoading,
        deleteWallet: handleDeleteWallet
    };
};
