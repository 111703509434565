import React, { FC, ReactElement } from 'react';
import { Tooltip } from '@mui/material';
import st from './ComingSoonWrapper.module.scss';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: ReactElement<any, any>;
    disabled?: boolean;
}

export const ComingSoonWrapper: FC<Props> = ({ children, disabled }) => {
    if (disabled) {
        return children;
    }
    return (
        <Tooltip className={st.comingSoon} followCursor title="Coming soon">
            {children}
        </Tooltip>
    );
};
