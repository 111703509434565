import { RefObject, useCallback, useEffect, useState } from 'react';

export const useScroll = (ref?: RefObject<HTMLDivElement>) => {
    const [isTop, setIsTop] = useState(true);
    const [isBottom, setIsBottom] = useState(false);

    const handleSetValues = useCallback((element: HTMLElement) => {
        const { scrollTop, scrollHeight, clientHeight } = element || {};
        if (scrollTop === 0) {
            setIsTop(true);
        } else {
            setIsTop(false);
        }
        if (clientHeight - (scrollHeight - scrollTop) >= 0) {
            setIsBottom(true);
        } else {
            setIsBottom(false);
        }
    }, []);

    useEffect(() => {
        if (!ref?.current) {
            setIsBottom(true);
            setIsTop(true);
        }
        if (ref?.current) {
            handleSetValues(ref.current);
        }
    }, [ref]);

    const onScrollHandler = useCallback((event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        handleSetValues(event.target as HTMLElement);
    }, []);

    return {
        onScroll: onScrollHandler,
        isBottom,
        isTop,
        setIsTop,
        setIsBottom
    };
};
