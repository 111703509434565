import { http, createConfig } from 'wagmi';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { injected, walletConnect } from 'wagmi/connectors';
import { BLAST_CHAIN } from 'constants/chains';
import { WALLET_CONNECT_PROJECT_ID } from 'constants/common';

declare module 'wagmi' {
    interface Register {
        config: typeof wagmiConfig;
    }
}

export const wagmiConfig = createConfig({
    chains: [BLAST_CHAIN],
    connectors: [
        walletConnect({ projectId: WALLET_CONNECT_PROJECT_ID, showQrModal: false }),
        injected({ target: 'metaMask' })
    ],
    transports: {
        [BLAST_CHAIN.id]: http(BLAST_CHAIN.rpcUrls.default.http[0])
    }
});

createWeb3Modal({
    wagmiConfig: wagmiConfig,
    projectId: WALLET_CONNECT_PROJECT_ID,
    allowUnsupportedChain: true
});
