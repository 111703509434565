import { apiGetUser } from 'api/endpoints/user';
import { useUserData } from 'context/hooks/useUserData';
import { useEffect, useState } from 'react';

export const useUserDto = () => {
    const [loading, setLoading] = useState(false);
    const {
        setUserData,
        userData: { token, uuid }
    } = useUserData();

    const fetchUserData = () => {
        if (token) {
            setLoading(true);
            return apiGetUser()
                .then(({ data }) => {
                    setUserData({
                        ...data,
                        wallets: data.wallets.sort((a) => (a.default ? -1 : 1))
                    });
                })
                .catch((err) => console.log(err))
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        if (token && !uuid) {
            fetchUserData();
        }
    }, [token]);

    return {
        fetchUserData,
        loading
    };
};
