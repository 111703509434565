// import { Navigate } from 'react-router-dom';
import { PATHS } from './paths';
import { Suspense, lazy } from 'react';
import { FullPageLoader } from 'components/shared/Loader/FullPageLoader';
import { PrivatePage } from 'pages/PrivatePage';
// import { LoginPage } from '../pages/LoginPage';

const LoginPage = lazy(() => import('pages/LoginPage'));
const DashboardPage = lazy(() => import('pages/DashboardPage'));
const LeaderboardPage = lazy(() => import('pages/LeaderboardPage'));
const WebClosePage = lazy(() => import('pages/WebClosePage'));
const TermsOfUsePage = lazy(() => import('pages/TermsOfUsePage'));
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicyPage'));
const StakePage = lazy(() => import('pages/StakePage'));

export const routers = [
    {
        path: PATHS.LOGIN,
        element: (
            <Suspense fallback={<FullPageLoader />}>
                <LoginPage />
            </Suspense>
        )
    },
    {
        path: PATHS.DASHBOARD,
        element: (
            <PrivatePage>
                <Suspense fallback={<FullPageLoader />}>
                    <DashboardPage />
                </Suspense>
            </PrivatePage>
        )
    },
    {
        path: PATHS.LEADERBOARD,
        element: (
            <PrivatePage>
                <Suspense fallback={<FullPageLoader />}>
                    <LeaderboardPage />
                </Suspense>
            </PrivatePage>
        )
    },
    {
        path: PATHS.STAKE,
        element: (
            <PrivatePage>
                <Suspense fallback={<FullPageLoader />}>
                    <StakePage />
                </Suspense>
            </PrivatePage>
        )
    },
    {
        path: PATHS.STAKE_BLAST,
        element: (
            <PrivatePage>
                <Suspense fallback={<FullPageLoader />}>
                    <StakePage type="blast" />
                </Suspense>
            </PrivatePage>
        )
    },
    {
        path: PATHS.STAKE_ETH,
        element: (
            <PrivatePage>
                <Suspense fallback={<FullPageLoader />}>
                    <StakePage type="eth" />
                </Suspense>
            </PrivatePage>
        )
    },
    {
        path: PATHS.TERMS_OF_USE,
        element: (
            <Suspense fallback={<FullPageLoader />}>
                <TermsOfUsePage />
            </Suspense>
        )
    },
    {
        path: PATHS.PRIVACY_POLICY,
        element: (
            <Suspense fallback={<FullPageLoader />}>
                <PrivacyPolicyPage />
            </Suspense>
        )
    }
    // {
    //     path: '*',
    //     element: <Navigate to={PATHS.LOGIN} replace />
    // }
];

export const closedRouters = [
    {
        path: '*',
        element: (
            <Suspense fallback={<FullPageLoader />}>
                <WebClosePage />
            </Suspense>
        )
    }
];
