export const getShortAddress = (address: string, endNumber?: number, startNumber?: number) => {
    const lastCharsNumber = endNumber || 4;
    return address.slice(0, startNumber || 4) + '....' + address.slice(-lastCharsNumber);
};

export const pad = (value: number | string, n = 2) =>
    (new Array(n).join('0') + value).slice(n * -1);

export const formatNumberWithCommas = (number: number): string => {
    return number.toLocaleString('en-US');
};

export const formatNumberWithSpaces = (number: number): string => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
