import React from 'react';
import cn from 'classnames';
import st from './NavMenu.module.scss';
import { MENU_ITEMS } from './menuItems';
import { Link, useLocation } from 'react-router-dom';
import { ComingSoonWrapper } from 'components/shared/ComingSoonWrapper/ComingSoonWrapper';

export const NavMenu = () => {
    const { pathname } = useLocation();

    return (
        <nav className={st.navMenu}>
            {MENU_ITEMS.map((item) => (
                <ComingSoonWrapper key={item.path} disabled={!item.comingSoon}>
                    {item.comingSoon ? (
                        <div
                            className={cn(
                                st.navMenuItem,
                                pathname.includes(item.path) && st.active,
                                item.comingSoon && st.disabled
                            )}>
                            {item.icon}
                            {item.title}
                        </div>
                    ) : (
                        <Link
                            to={item.path}
                            className={cn(
                                st.navMenuItem,
                                pathname.includes(item.path) && st.active,
                                item.comingSoon && st.disabled
                            )}>
                            {item.icon}
                            {item.title}
                        </Link>
                    )}
                </ComingSoonWrapper>
            ))}
        </nav>
    );
};
