import { queryClient } from 'configs/query';
import { PATHS, PATHS_WITHOUT_HEADER } from 'constants/paths';

export const logout = async () => {
    await localStorage.clear();
    await queryClient.clear();
    if (!PATHS_WITHOUT_HEADER.includes(window.location.pathname)) {
        location.href = PATHS.LOGIN;
    }
};
